import React from 'react'
import { Helmet } from "react-helmet"

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (
    props
) => {
    return (
        <div className={props.className}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Devin Munsch - Web Developer</title>
                <meta name="description" content="Developer based in Münster. Creating Web Applications with Laravel and React" />
            </Helmet>
            <Header />
                <div className="relative mt-3">
                    {props.children}
                </div>
            <Footer />
        </div>
    )
}

export default Layout