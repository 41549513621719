import React from 'react'
//import { Link } from 'gatsby'

const Header = () => {
    return (
        <header>
            <div className="max-w-screen-xl mx-auto relative top-0 z-20 flex flex-col items-center justify-between w-full px-4 py-4 md:py-8 mx-auto lg:flex-row ">
                
            </div>
        </header>
    )
}

export default Header

/*
    <Link to="/" className="text-gray-900">
        Web Developer
    </Link>

    <div className="flex flex-wrap justify-center items-center mt-12 -ml-16 lg:mt-0">
        <Link to="/" className="menu-link ml-16 text-base font-bold text-gray-900 tracking-widest uppercase hover:opacity-50 lg:mt-0">Home</Link>
    </div>
*/