import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const Footer = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <footer>
            <div className="max-w-screen-xl px-4 mx-auto mt-20 lg:mt-40 mb-10">
                <div className="flex flex-col items-center md:flex-row md:justify-between">
                    <span className="mt-4">© {data.site.siteMetadata.title}</span>

                    <div className="flex flex-col items-center justify-center lg:flex-row">
                        <Link to="/" className="menu-link mt-4 md:ml-12 text-base text-gray-500">Start</Link>
                        <Link to="/privacy-policy" className="menu-link mt-4 md:ml-12 text-base text-gray-500">Privacy Policy</Link>
                        <Link to="/imprint" className="menu-link mt-4 md:ml-12 text-base text-gray-500">Imprint</Link>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer